import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Popover } from 'antd';
import sms from '../../sms';
import SmsInfo from './SmsInfo';
import SmsDisplay from './SmsDisplay';

const Container = styled.div`
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 30px 0;
`;
const Blind = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: black;
  opacity: 0.1;
`;
const AlertButton = styled(Button)`
  position: absolute;
  z-index: 2;
  top: 10px;
`;

const OrderMessenger = (props) => {
  const {
    orderProductDatas,
    orderData,
    pageType,
    setSmsDone,
    setOrderData,
    handleCreate,
    smsDone,
    orderSaleDatas,
  } = props;

  const { sendAllinda, sendPayapp } = sms();

  const [isDisable, setIsDisable] = useState(false);
  const [mobile, setMobile] = useState('');
  const [smsType, setSmsType] = useState('request');
  const [genLinkLoading, setGenLinkLoading] = useState(false);

  const [reqSmsDone, setReqSmsDone] = useState(false);
  const [conSmsDone, setConSmsDone] = useState(false);

  // 패키지 텍스트 여부
  const [isOnPackageText, setIsOnPackageText] = useState(false);
  // 배송비 텍스트 여부
  const [isOnDlvyText, setIsOnDlvyText] = useState(true);
  // sms에 찍힐 데이터
  const [smsData, setSmsData] = useState({
    packageInfos: '',
    productInfos: '',
    deliveryPrice: 0,
    totalPrice: 0,
    payUrl: '',
  });
  // sms에 입력할 메시지
  const [reqSmsInput, setReqSmsInput] = useState(`
3시 이후 확인된 입금은 평일기준 다음날 출고되며 입금이 확인되지 않으면 출고되지 않습니다.\n
한통도시락은 제품의 특성상 택배배송이 평일기준 3~5일 소요됩니다.
`);
  const [conSmsInput, setConSmsInput] = useState(`
입금이 확인되었습니다. 주문해주셔서 감사합니다.\n
한통도시락은 제품의 특성상 택배배송이 평일기준 3~5일 소요됩니다.
`);
  useEffect(() => {
    if (
      orderData.payment_terms === '법인카드' ||
      orderData.payment_terms === '신용카드'
    ) {
      setReqSmsInput(`
3시 이후 확인된 결제는 평일기준 다음날 출고되며 결제가 확인되지 않으면 출고되지 않습니다.\n
한통도시락은 제품의 특성상 택배배송이 평일기준 3~5일 소요됩니다.
    `);
      setConSmsInput(`
결제가 확인되었습니다. 주문해주셔서 감사합니다.\n
한통도시락은 제품의 특성상 택배배송이 평일기준 3~5일 소요됩니다.
    `);
    } else {
      setReqSmsInput(`
3시 이후 확인된 입금은 평일기준 다음날 출고되며 입금이 확인되지 않으면 출고되지 않습니다.\n
한통도시락은 제품의 특성상 택배배송이 평일기준 3~5일 소요됩니다.
      `);
      setConSmsInput(`
입금이 확인되었습니다. 주문해주셔서 감사합니다.\n
한통도시락은 제품의 특성상 택배배송이 평일기준 3~5일 소요됩니다.
      `);
    }
  }, [orderData.payment_terms]);
  // sms에서 출력될 메시지
  const [reqSmsResult, setReqSmsResult] = useState('');
  const [conSmsResult, setConSmsResult] = useState('');
  // 계좌번호 메시지
  const bankNumMessage = `계좌: 기업은행 (주)한통 555-046376-04-033\n`;

  // SMS를 보낸 전화번호
  useEffect(() => {
    if (pageType === 'detail' || pageType === 'update') {
      setMobile(orderData.sms_phone);
    }
  }, [pageType, orderData.sms_phone]);

  useEffect(() => {
    // 결제요청 메시지
    let reqSms = '';
    if (
      orderData.payment_terms === '법인카드' ||
      orderData.payment_terms === '신용카드'
    ) {
      reqSms = `${isOnPackageText ? `패키지: ${smsData.packageInfos}\n` : ''}
주문상품: ${smsData.productInfos}\n
${isOnDlvyText ? `배송비: ${smsData.deliveryPrice.toLocaleString()}원\n` : ''}
총액: ${smsData.totalPrice.toLocaleString()}원 (배송비 포함)\n
결제링크: ${smsData.payUrl}\n
${reqSmsInput}`;
    } else {
      reqSms = `${isOnPackageText ? `패키지: ${smsData.packageInfos}\n` : ''}
주문상품: ${smsData.productInfos}\n
${isOnDlvyText ? `배송비: ${smsData.deliveryPrice.toLocaleString()}원\n` : ''}
총액: ${smsData.totalPrice.toLocaleString()}원 (배송비 포함)\n
${bankNumMessage}
${reqSmsInput}`;
    }
    setReqSmsResult(reqSms);
    // 입금확인 메시지
    setConSmsResult(conSmsInput);
  }, [
    smsData,
    reqSmsInput,
    conSmsInput,
    orderData.payment_terms,
    bankNumMessage,
    genLinkLoading,
    isOnDlvyText,
    isOnPackageText,
  ]);

  useEffect(() => {
    let packageInfos = '';
    let productInfos = '';
    let supplyPrice = 0;
    let vatPrice = 0;
    let totalPrice = 0;
    let deliveryPrice = 0;
    let discountPrice = 0;

    for (const data of orderSaleDatas) {
      const { sale_name, sale_quantity } = data;
      let packageInfo = `${sale_name} ${sale_quantity}세트, `;
      packageInfos += packageInfo;
    }
    for (const data of orderProductDatas) {
      if (
        (data.product_code.includes('parcel') &&
          !data.product_code.includes('parcelbox')) ||
        data.product_code.includes('COD') ||
        data.product_code.includes('DC')
      ) {
        deliveryPrice += data.product_supply_price + data.product_VAT_price;
      } else {
        const { product_quantity, product_name } = data;
        let productInfo = `${product_name}(${product_quantity}), `;
        // 주황보온용기 4+1 표시
        if (productInfo.includes('주황보온용기') && product_quantity === 5) {
          productInfo = '주황보온용기4+1(5), ';
        }
        // 실링비닐 뒤 알파벳 제거
        if (productInfo.includes('실링비닐')) {
          productInfo = `${product_name.slice(0, -1)}(${product_quantity}), `;
        }
        productInfos += productInfo;
        supplyPrice += data.product_supply_price;
        vatPrice += data.product_VAT_price;
      }
      // 할인 처리
      if (data.discount_rate && data.discount_rate > 0) {
        const { product_VAT_price, product_supply_price, discount_rate } = data;
        const relPrice = product_VAT_price + product_supply_price;
        const dcPrice = (relPrice * discount_rate) / 100;
        discountPrice += dcPrice;
      }
    }
    packageInfos = packageInfos.slice(0, -2);
    productInfos = productInfos.slice(0, -2);
    totalPrice = supplyPrice + vatPrice + deliveryPrice - discountPrice;
    setSmsData((prev) => ({
      ...prev,
      packageInfos,
      productInfos,
      deliveryPrice: Math.round(deliveryPrice),
      totalPrice: Math.round(totalPrice),
    }));
  }, [orderProductDatas, orderSaleDatas]);

  const getAccountMobile = () => {
    if (!orderData.account_phone) {
      alert('거래처전화가 없습니다.');
      return;
    }
    const accountMobile = orderData.account_phone.replace(/-/g, '');
    setMobile(accountMobile);
    setIsDisable(true);
  };

  const handleSms = async (value) => {
    if (!orderData.payment_terms) {
      alert('결제방법을 선택해주세요.');
      return;
    }
    if (mobile.trim().length === 0) {
      alert('SMS를 보낼 전화번호를 입력해주세요.');
      return;
    }
    if (
      (orderData.payment_terms === '법인카드' ||
        orderData.payment_terms === '신용카드') &&
      smsData.payUrl.length === 0
    ) {
      alert('결제링크를 먼저 생성해주세요.');
      return;
    }
    const mobileArr = [{ phoneNumber: mobile }];

    await sendAllinda({
      recipients: mobileArr,
      contents:
        (value === 'messenger' && reqSmsResult) ||
        (value === 'confirm' && conSmsResult),
    });
    await setOrderData((prev) => ({ ...prev, sms_phone: mobile }));
    // 요청 SMS 전송여부 (주문서 추가시 전송여부 확인용)
    await setSmsDone(true);
    // 요청 및 확인 SMS 전송여부 (전송버튼 disable처리용)
    if (value === 'messenger') {
      await setReqSmsDone(true);
    }
    if (value === 'confirm') {
      await setConSmsDone(true);
    }
  };

  const handlePayapp = async () => {
    if (mobile.trim().length === 0) {
      alert('결제링크를 보낼 전화번호를 입력해주세요.');
      return;
    }
    try {
      await setGenLinkLoading(true);
      // 핸드폰번호, 상품명, 결제금액
      const { productInfos, totalPrice } = smsData;
      const payUrlPromise = sendPayapp({
        recvphone: mobile,
        goodname: productInfos,
        price: totalPrice,
        smsuse: 'N',
      });
      payUrlPromise.then((res) => {
        if (!res) {
          setGenLinkLoading(false);
          return;
        }
        setSmsData((prev) => ({ ...prev, payUrl: res.payUrl }));
        setOrderData((prev) => ({
          ...prev,
          mul_no: res.payCode,
        }));
        setGenLinkLoading(false);
      });
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  // sms전송 -> 주문서추가 묻는 함수
  useEffect(() => {
    if (smsDone) {
      handleCreate();
    }
  }, [smsDone]);

  return (
    <Container>
      {(!orderData.payment_terms || orderData.payment_terms === '스토어') && (
        <>
          <Blind />
          <AlertButton type="primary" danger>
            결제조건을 선택해주세요.
          </AlertButton>
        </>
      )}
      {(!orderData.payment_terms || orderData.payment_terms === '스토어') && (
        <Popover
          content={
            <p>
              {orderData.payment_terms === '스토어'
                ? '결제조건이 스토어이므로 SMS를 발송할 수 없습니다.'
                : '결제조건을 선택해주세요.'}
            </p>
          }
        >
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: '1',
              cursor: 'not-allowed',
            }}
          />
        </Popover>
      )}
      <SmsInfo
        setOrderData={setOrderData}
        setSmsType={setSmsType}
        setMobile={setMobile}
        setIsDisable={setIsDisable}
        getAccountMobile={getAccountMobile}
        handlePayapp={handlePayapp}
        handleSms={handleSms}
        mobile={mobile}
        isDisable={isDisable}
        pageType={pageType}
        smsType={smsType}
        orderData={orderData}
        reqSmsInput={reqSmsInput}
        setReqSmsInput={setReqSmsInput}
        conSmsInput={conSmsInput}
        setConSmsInput={setConSmsInput}
        genLinkLoading={genLinkLoading}
        isOnDlvyText={isOnDlvyText}
        setIsOnDlvyText={setIsOnDlvyText}
        isOnPackageText={isOnPackageText}
        setIsOnPackageText={setIsOnPackageText}
      />
      <SmsDisplay
        smsType={smsType}
        pageType={pageType}
        handleSms={handleSms}
        reqSmsDone={reqSmsDone}
        conSmsDone={conSmsDone}
        reqSmsInput={reqSmsInput}
        conSmsInput={conSmsInput}
        smsData={smsData}
        reqSmsResult={reqSmsResult}
        conSmsResult={conSmsResult}
      />
    </Container>
  );
};

export default OrderMessenger;

OrderMessenger.propTypes = {
  orderProductDatas: PropTypes.instanceOf(Array).isRequired,
  orderData: PropTypes.instanceOf(Object).isRequired,
  pageType: PropTypes.string.isRequired,
  setSmsDone: PropTypes.func.isRequired,
  setOrderData: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  smsDone: PropTypes.bool.isRequired,
  orderSaleDatas: PropTypes.instanceOf(Array).isRequired,
};
