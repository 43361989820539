import {
  EditOutlined,
  FallOutlined,
  RiseOutlined,
  SaveOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import { Button, Input, Modal, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
  darkModeColors,
  lightModeColors,
} from '../../constants';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import MonthlySalesTarget from './MonthlySalesTarget';

const AnalyticsStandard = () => {
  const { darkMode } = useSelector((state) => state.darkMode);
  const Colors = darkMode ? darkModeColors : lightModeColors;
  const dispatch = useDispatch();
  const [salesLevel, setSalesLevel] = useState({
    level1Min: 0,
    level1Max: 0,
    level2Min: 0,
    level2Max: 0,
    level3Min: 0,
    level3Max: 0,
    level4Min: 0,
    level4Max: 0,
    level5Min: 0,
    id: null,
    updatedAt: null,
  });
  const [salesLevelInput, setSalesLevelInput] = useState({
    level1Min: 0,
    level1Max: 0,
    level2Min: 0,
    level2Max: 0,
    level3Min: 0,
    level3Max: 0,
    level4Min: 0,
    level4Max: 0,
    level5Min: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getSalesStandard = async () => {
      try {
        const fetchedSalesLevel = await dispatch(
          asyncReadDataFetch({ table: 'calendar_sales_level' })
        )
          .unwrap()
          .then((res) => res[0]);
        setSalesLevel({
          level1Min: fetchedSalesLevel.level1_min,
          level1Max: fetchedSalesLevel.level1_max,
          level2Min: fetchedSalesLevel.level2_min,
          level2Max: fetchedSalesLevel.level2_max,
          level3Min: fetchedSalesLevel.level3_min,
          level3Max: fetchedSalesLevel.level3_max,
          level4Min: fetchedSalesLevel.level4_min,
          level4Max: fetchedSalesLevel.level4_max,
          level5Min: fetchedSalesLevel.level5_min,
          id: fetchedSalesLevel.id,
          updatedAt: fetchedSalesLevel.updated_at,
        });
        setSalesLevelInput({
          level1Min: fetchedSalesLevel.level1_min,
          level1Max: fetchedSalesLevel.level1_max,
          level2Min: fetchedSalesLevel.level2_min,
          level2Max: fetchedSalesLevel.level2_max,
          level3Min: fetchedSalesLevel.level3_min,
          level3Max: fetchedSalesLevel.level3_max,
          level4Min: fetchedSalesLevel.level4_min,
          level4Max: fetchedSalesLevel.level4_max,
          level5Min: fetchedSalesLevel.level5_min,
        });
      } catch (error) {
        throw new Error(error);
      }
    };
    getSalesStandard();
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    const numberedValue = Number(value);
    setSalesLevelInput((prev) => ({ ...prev, [name]: numberedValue }));
  };

  const lowerMaxNotbigger = (lowerMax, higherMin) => {
    return higherMin > lowerMax;
  };

  const handleUpdateStandard = async () => {
    const confirmUpdateStandard = window.confirm(
      '매출 수준 기준값을 저장하시겠습니까?'
    );
    if (!confirmUpdateStandard) {
      return;
    }
    const { id } = salesLevel;
    const {
      level1Min,
      level1Max,
      level2Min,
      level2Max,
      level3Min,
      level3Max,
      level4Min,
      level4Max,
      level5Min,
    } = salesLevelInput;
    if (!lowerMaxNotbigger(level1Max, level2Min)) {
      alert('매출 수준: 매우 낮음의 max 값이 낮음의 min 값보다 작아야합니다.');
      return;
    }
    if (!lowerMaxNotbigger(level2Max, level3Min)) {
      alert('매출 수준: 낮음의 max 값이 보통의 min 값보다 작아야합니다.');
      return;
    }
    if (!lowerMaxNotbigger(level3Max, level4Min)) {
      alert('매출 수준: 보통의 max 값이 높음의 min 값보다 작아야합니다.');
      return;
    }
    if (!lowerMaxNotbigger(level4Max, level5Min)) {
      alert('매출 수준: 높음의 max 값이 매우높음의 min 값보다 작아야합니다.');
      return;
    }
    try {
      await dispatch(
        asyncUpdateDataFetch({
          table: 'calendar_sales_level',
          id,
          level1_min: level1Min,
          level1_max: level1Max,
          level2_min: level2Min,
          level2_max: level2Max,
          level3_min: level3Min,
          level3_max: level3Max,
          level4_min: level4Min,
          level4_max: level4Max,
          level5_min: level5Min,
          updated_at: dayjs(),
        })
      );
      alert('매출 수준 기준이 수정되었습니다. 새로고침 해주세요.');
      setIsModalOpen(false);
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <div
      style={{
        // border: '1px solid #efefef',
        padding: '8px 10px',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '10px',
        gap: '10px',
        color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
      }}
    >
      {/* 
      <div
        style={{
          width: '70%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '2.5px',
        }}
      >
        <ContainerDiv>
          <span
            style={{
              marginRight: '5px',
              color: Colors.text_color,
              fontSize: '12px',
            }}
          >
            전일 대비 상승
          </span>
          <LineDiv />
          <Button
            size="small"
            type="primary"
            style={{ backgroundColor: Colors.armsone_blue }}
          >
            <RiseOutlined />
          </Button>
        </ContainerDiv>
        <ContainerDiv>
          <span
            style={{
              marginRight: '5px',
              color: Colors.text_color,
              fontSize: '12px',
            }}
          >
            전일 대비 하락
          </span>
          <LineDiv />
          <Button
            size="small"
            type="primary"
            style={{ backgroundColor: Colors.armsone_red }}
          >
            <FallOutlined />
          </Button>
        </ContainerDiv>
        <ContainerDiv>
          <span style={{ color: Colors.text_color, fontSize: '12px' }}>
            당월 최고 매출
          </span>
          <LineDiv />
          <Button
            size="small"
            style={{
              backgroundColor: Colors.trophy_bg,
              borderColor: Colors.trophy_bg,
            }}
          >
            <TrophyOutlined style={{ color: Colors.trophy_icon }} />
          </Button>
        </ContainerDiv>
      </div>
       
      <div
        style={{
          height: '80%',
          borderLeft: darkMode ? '0.5px solid gray' : '0.5px solid lightgray',
        }}
      />
       */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          width: '100%',
        }}
      >
        <Button
          size="small"
          style={{
            fontSize: '12px',
            color: 'gray',
            backgroundColor: darkMode
              ? DARK_MODE_BACKGROUND
              : LIGHT_MODE_BACKGROUND,
            border: darkMode && 'none',
          }}
          onClick={() => setIsModalOpen(true)}
        >
          매출 수준값
        </Button>
        <MonthlySalesTarget darkMode={darkMode} />
      </div>
      {isModalOpen && (
        <Modal
          centered
          open
          onOk={handleUpdateStandard}
          onCancel={() => setIsModalOpen(false)}
          okText={
            <div>
              <SaveOutlined /> 저장
            </div>
          }
          cancelText="닫기"
          title="매출수준 기준값 수정"
        >
          <div style={{ padding: '10px 0' }}>
            <div
              style={{
                color: 'gray',
                fontSize: '10px',
                textAlign: 'right',
                marginBottom: '5px',
              }}
            >
              최신수정일: {salesLevel.updatedAt?.slice(0, 16)}
            </div>
            <ContainerDiv>
              <FlexDiv>
                <WidthInput
                  value={salesLevelInput.level1Min}
                  type="number"
                  name="level1Min"
                  onChange={(e) => handleChangeInput(e)}
                />
                ~
                <WidthInput
                  value={salesLevelInput.level1Max}
                  type="number"
                  name="level1Max"
                  onChange={(e) => handleChangeInput(e)}
                />
              </FlexDiv>
              <NoMarginTag>위험</NoMarginTag>
            </ContainerDiv>
            <ContainerDiv>
              <FlexDiv>
                <WidthInput
                  value={salesLevelInput.level2Min}
                  type="number"
                  name="level2Min"
                  onChange={(e) => handleChangeInput(e)}
                />
                ~
                <WidthInput
                  value={salesLevelInput.level2Max}
                  type="number"
                  name="level2Max"
                  onChange={(e) => handleChangeInput(e)}
                />
              </FlexDiv>
              <NoMarginTag>낮음</NoMarginTag>
            </ContainerDiv>
            <ContainerDiv>
              <FlexDiv>
                <WidthInput
                  value={salesLevelInput.level3Min}
                  type="number"
                  name="level3Min"
                  onChange={(e) => handleChangeInput(e)}
                />
                ~
                <WidthInput
                  value={salesLevelInput.level3Max}
                  type="number"
                  name="level3Max"
                  onChange={(e) => handleChangeInput(e)}
                />
              </FlexDiv>
              <NoMarginTag>보통</NoMarginTag>
            </ContainerDiv>
            <ContainerDiv>
              <FlexDiv>
                <WidthInput
                  value={salesLevelInput.level4Min}
                  type="number"
                  name="level4Min"
                  onChange={(e) => handleChangeInput(e)}
                />
                ~
                <WidthInput
                  value={salesLevelInput.level4Max}
                  type="number"
                  name="level4Max"
                  onChange={(e) => handleChangeInput(e)}
                />
              </FlexDiv>
              <NoMarginTag>높음</NoMarginTag>
            </ContainerDiv>
            <ContainerDiv>
              <FlexDiv>
                <WidthInput
                  value={salesLevelInput.level5Min}
                  type="number"
                  name="level5Min"
                  onChange={(e) => handleChangeInput(e)}
                />
                ~
                <WidthDiv />
              </FlexDiv>
              <NoMarginTag>최고</NoMarginTag>
            </ContainerDiv>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AnalyticsStandard;

const ContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
const WidthDiv = styled.div`
  max-width: 90px;
  text-align: center;
`;
const WidthInput = styled(Input)`
  max-width: 90px;
  padding: 0px 3px;
  margin: 0px;
  border-radius: 0px;
`;
const LineDiv = styled.div`
  content: '';
  flex: 1;
  height: 0.5px;
  background-color: lightgray;
  margin: 0 10px;
`;
const MarginH4 = styled.h4`
  margin-bottom: 10px;
`;
const NoMarginTag = styled(Tag)`
  margin: 0;
`;
