import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Tag } from 'antd';
import styled from 'styled-components';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const TagStyle = styled(Tag)`
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 0px;
  margin-right: 10px;
`;

const AccountBasicInfo = (props) => {
  const { orderData } = props;
  const dispatch = useDispatch();

  const [basicInfo, setBasicInfo] = useState({
    machineVinyl: '',
    briefs: '',
    paymentType: '',
  });

  useEffect(() => {
    dispatch(
      asyncReadFilteredDataFetch({
        table: 'account',
        eqKey: 'code',
        eqValue: orderData.account_code,
      })
    )
      .unwrap()
      .then((res) => {
        const { machine_vinyl, briefs, payment_type } = res[0];
        setBasicInfo({
          machineVinyl: machine_vinyl,
          briefs,
          paymentType: payment_type,
        });
      })
      .catch((res) => {
        console.log(res);
        alert(res.message);
      });
  }, [dispatch, orderData.account_code]);

  return (
    <div>
      {basicInfo.machineVinyl?.length === 0 ? (
        <TagStyle color="red">기계/비닐 정보 없음</TagStyle>
      ) : (
        <TagStyle color="blue">{basicInfo.machineVinyl}</TagStyle>
      )}
      {basicInfo.briefs?.length === 0 ? (
        <TagStyle color="red">적요 정보 없음</TagStyle>
      ) : (
        <TagStyle color="blue">{basicInfo.briefs}</TagStyle>
      )}
      {basicInfo.paymentType?.length === 0 ? (
        <TagStyle color="red">기본결제 정보 없음</TagStyle>
      ) : (
        <TagStyle color="blue">{basicInfo.paymentType}</TagStyle>
      )}
    </div>
  );
};

export default AccountBasicInfo;

AccountBasicInfo.propTypes = {
  orderData: PropTypes.instanceOf(Object).isRequired,
};
