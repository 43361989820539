import dayjs from 'dayjs';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Badge, Popover } from 'antd';
import PropTypes from 'prop-types';
import { QuestionCircleFilled } from '@ant-design/icons';

import {
  botNames,
  DARK_MODE_BACKGROUND,
  LIGHT_MODE_BACKGROUND,
} from '../../constants';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

const BotOperationChecker = (props) => {
  const { isLargeScreen } = props;
  const { darkMode } = useSelector((state) => state.darkMode);
  const dispatch = useDispatch();

  const bgColor = darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND;

  const [botOperationStatus, setBotOperationStatus] = useState({
    headOffice: { isOperated: false, pingDate: '', name: '' },
    ceoHome: { isOperated: false, pingDate: '', name: '' },
    hapjeongOffice: { isOperated: false, pingDate: '', name: '' },
  });

  useEffect(() => {
    const fetchBotPingData = async () => {
      const response = await dispatch(
        asyncReadDataFetch({ table: 'hantong_bot_ping' })
      ).unwrap();
      return response;
    };

    const checkIsOperated = (pingDate) => {
      const current = dayjs();
      return current.diff(dayjs(pingDate), 'minute') <= 10;
    };

    const handleBotOperationStatus = ({ pingDate, name, key }) => {
      const isOperated = checkIsOperated(pingDate);
      setBotOperationStatus((prev) => ({
        ...prev,
        [key]: { isOperated, pingDate, name },
      }));
    };

    const processCheckBotOperation = async () => {
      const botPingData = await fetchBotPingData();

      for (const data of botPingData) {
        const { name, ping_date: pingDate } = data;
        switch (name) {
          case botNames.headOffice:
            handleBotOperationStatus({ pingDate, name, key: 'headOffice' });
            break;
          case botNames.ceoHome:
            handleBotOperationStatus({ pingDate, name, key: 'ceoHome' });
            break;
          case botNames.hapjeongOffice:
            handleBotOperationStatus({ pingDate, name, key: 'hapjeongOffice' });
            break;
          default:
            break;
        }
      }
    };

    processCheckBotOperation();
  }, []);

  const popoverContent = useMemo(() => {
    const statusKeys = Object.keys(botOperationStatus);

    const contentTexts = statusKeys.map((key) => {
      const { pingDate, name } = botOperationStatus[key];
      return <li style={{ listStyle: 'none' }}>{`${name}: ${pingDate}`}</li>;
    });

    return (
      <div style={{ color: 'gray', fontSize: '10px' }}>
        <div style={{ marginBottom: '5px' }}>
          한통봇 PING DATETIME은 10분마다 업데이트됩니다.
        </div>
        <ul>{contentTexts}</ul>
      </div>
    );
  }, [botOperationStatus]);

  return (
    <div
      style={{
        // border: '1px solid #efefef',
        backgroundColor: bgColor,
        color: 'gray',
        width: '100%',
        height: '100%',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        borderRadius: '10px',
        position: 'relative',
      }}
    >
      <h4 style={{ position: 'relative' }}>
        <span>Server status</span>
        <Popover
          content={popoverContent}
          trigger="click"
          placement="left"
          color={bgColor}
        >
          <QuestionCircleFilled
            style={{ position: 'absolute', right: '0px', cursor: 'pointer' }}
          />
        </Popover>
      </h4>
      <div>
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            gap: isLargeScreen ? '0px' : '10px',
            flexDirection: isLargeScreen && 'column',
            justifyContent: !isLargeScreen && 'center',
            fontSize: '12.5px',
          }}
        >
          <div
            style={{
              display: isLargeScreen && 'flex',
              justifyContent: isLargeScreen && 'space-between',
            }}
          >
            <span>장항</span>
            <Badge
              status={
                botOperationStatus.headOffice.isOperated
                  ? 'processing'
                  : 'error'
              }
              style={{ marginLeft: '5px' }}
            />
          </div>
          <div
            style={{
              display: isLargeScreen && 'flex',
              justifyContent: isLargeScreen && 'space-between',
            }}
          >
            <span>인천</span>
            <Badge
              status={
                botOperationStatus.hapjeongOffice.isOperated
                  ? 'processing'
                  : 'error'
              }
              style={{ marginLeft: '5px' }}
            />
          </div>
          <div
            style={{
              display: isLargeScreen && 'flex',
              justifyContent: isLargeScreen && 'space-between',
            }}
          >
            <span>삼송</span>
            <Badge
              status={
                botOperationStatus.ceoHome.isOperated ? 'processing' : 'error'
              }
              style={{ marginLeft: '5px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotOperationChecker;

BotOperationChecker.propTypes = {
  isLargeScreen: PropTypes.bool.isRequired,
};
