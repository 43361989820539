import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import DraggableCard from './DraggableCard';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncReadBulkDataFetch } from '../../redux/modules/shared/readBulkData';

const DraggableContainer = (props) => {
  const { saleDatas, setSaleDatas } = props;
  const dispatch = useDispatch();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCards(saleDatas.map((item, index) => ({ ...item, id: index + 1 })));
  }, [saleDatas]);

  const findCard = (id) => {
    const card = cards.filter((item) => item.id === id)[0];
    return { card, index: cards.indexOf(card) };
  };

  const moveCard = (id, atIndex) => {
    const { card, index } = findCard(id);
    const updatedCards = [...cards];
    updatedCards.splice(index, 1);
    updatedCards.splice(atIndex, 0, card);
    setCards([...updatedCards]);
  };

  const deleteSortOrder = async (code) => {
    try {
      await dispatch(
        asyncUpdateDataFetch({ table: 'sale', code, sort_order: null })
      );
      await setCards(cards.filter((card) => card.sale_code !== code));
      await setSaleDatas(saleDatas.filter((data) => data.sale_code !== code));
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const saveSortOrder = async () => {
    try {
      await setLoading(true);
      const newSaleDatas = cards.map((card, index) => ({
        delivery: card.delivery,
        sale_code: card.sale_code,
        sale_name: card.sale_name,
        sale_quantity: card.sale_quantity,
        sort_order: index + 1,
      }));
      // saleDatas와 newSaleDatas를 비교하여 sort_order속성이 변화된 데이터
      const codesOfChangedDatas = [];
      for (const saleData of saleDatas) {
        for (const newSaleData of newSaleDatas) {
          if (
            saleData.sale_code === newSaleData.sale_code &&
            saleData.sort_order !== newSaleData.sort_order
          ) {
            codesOfChangedDatas.push(newSaleData.sale_code);
          }
        }
      }
      // bulk 업데이트를 위한 sort_order속성이 변화된 데이터의 기존 sale데이터 불러오기
      const originalDatas = await dispatch(
        asyncReadBulkDataFetch({
          table: 'sale',
          column: 'code',
          array: codesOfChangedDatas,
        })
      )
        .unwrap()
        .then((res) => res);
      // bulk 업데이트를 할 데이터 구성
      const datasToBulkUpdate = [];
      for (const originalData of originalDatas) {
        for (const newSaleData of newSaleDatas) {
          if (originalData.code === newSaleData.sale_code) {
            datasToBulkUpdate.push({
              ...originalData,
              sort_order: newSaleData.sort_order,
            });
          }
        }
      }
      // bulk 업데이트
      await dispatch(
        asyncUpdateDataFetch({
          table: 'sale',
          isBulk: true,
          bulkDatas: datasToBulkUpdate,
        })
      );
      await setSaleDatas(newSaleDatas);
      await setLoading(false);
      alert('정렬순서가 저장되었습니다.');
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  return (
    <div
      style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'center' }}
    >
      <Popover
        content={<div>원하는 정렬순서에 맞게 드래그하세요.</div>}
        placement="right"
      >
        <InfoCircleOutlined
          style={{
            color: '#1677ff',
            position: 'absolute',
            top: '24px',
            left: '102px',
          }}
        />
      </Popover>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          borderRadius: '30px',
          padding: '10px',
          backgroundColor: '#efefef',
          justifyContent: 'center',
        }}
      >
        {cards.map((card, index) => (
          <DraggableCard
            key={card.id}
            id={card.id}
            card={card}
            moveCard={moveCard}
            findCard={findCard}
            deleteSortOrder={deleteSortOrder}
            cardIdx={index}
          />
        ))}
      </div>
      <div
        style={{
          width: '100%',
          backgroundColor: '#efefef',
          margin: '15px 0',
          borderRadius: '20px',
          padding: '15px 0',
        }}
      >
        <Button
          style={{ borderRadius: '20px' }}
          size="large"
          onClick={saveSortOrder}
          loading={loading}
        >
          <SaveOutlined />
          정렬순서 저장하기
        </Button>
      </div>
    </div>
  );
};

export default DraggableContainer;

DraggableContainer.propTypes = {
  saleDatas: PropTypes.instanceOf(Array).isRequired,
  setSaleDatas: PropTypes.func.isRequired,
};
