import { Button, Card, Input, Modal, Radio, Select, Statistic } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { SaveOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncReadMatchDataFetch } from '../../redux/modules/shared/readMatchData';
import { inventoryCouse } from '../../constants';
import useQueryFunctions from '../../hook/useQueryFunctions';

const AdjustmentModal = (props) => {
  const { setIsOpenAdjustmentModal, setTableDatas } = props;
  const dispatch = useDispatch();
  const adminId = useSelector((state) => state.signinUser.userInfo.id);
  const { fnInsertInventoryAdjustment } = useQueryFunctions();

  const [formData, setFormData] = useState({
    inventoryChangeDate: dayjs().format('YYYY-MM-DD'),
    product: null,
    warehouse: null,
    quantity: null,
  });
  const [options, setOptions] = useState({
    warehouse: [],
    product: [],
  });
  const [qtyType, setQtyType] = useState('absolute');
  const [stackQuantityData, setStackQuantityData] = useState({
    originalQuantity: 0,
    newQuantity: 0,
  });
  const [isInputable, setIsInputable] = useState(false);

  useEffect(() => {
    const getRecentInventory = async () => {
      try {
        // 최근 재고 데이터 받기
        const recentInventory = await dispatch(
          asyncReadMatchDataFetch({
            table: 'inventory',
            match: {
              warehouse_id: formData.warehouse,
              product_id: formData.product,
            },
            order: 'id',
            limit: 1,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        if (!recentInventory) {
          setStackQuantityData((prev) => ({ ...prev, originalQuantity: 0 }));
        } else {
          const recentStackQuantity = recentInventory.stack_quantity;
          setStackQuantityData((prev) => ({
            ...prev,
            originalQuantity: recentStackQuantity,
          }));
        }
        setIsInputable(true);
      } catch (error) {
        throw new Error(error);
      }
    };
    if (formData.product && formData.warehouse) {
      getRecentInventory();
    }
  }, [formData.product, formData.warehouse, dispatch]);

  useEffect(() => {
    const getOptions = async () => {
      const warehouse = await dispatch(
        asyncReadDataFetch({ table: 'warehouse' })
      )
        .unwrap()
        .then((res) => res);
      const product = await dispatch(asyncReadDataFetch({ table: 'product' }))
        .unwrap()
        .then((res) => res);
      // 취급 품목 가져오기
      const setupProduct = await dispatch(
        asyncReadDataFetch({ table: 'inventory_product_setup' })
      )
        .unwrap()
        .then((res) => res);
      // 품목 옵션을 취급 품목으로 필터링
      const filteredProduct = product.filter((item) => {
        let isSetup = false;
        setupProduct.forEach((setupItem) => {
          if (item.id === setupItem.product_id) isSetup = true;
        });
        return isSetup;
      });
      setOptions({
        warehouse: warehouse.map((data) => ({
          label: data.name,
          value: data.id,
        })),
        product: filteredProduct.map((data) => ({
          label: data.name,
          value: data.id,
        })),
      });
    };
    getOptions();
  }, [dispatch]);

  const handleChangeQuantity = (e) => {
    const { value } = e.target;
    const quantity = Number(value);
    if (qtyType === 'variation') {
      const newQuantity = stackQuantityData.originalQuantity + quantity;
      setStackQuantityData((prev) => ({ ...prev, newQuantity }));
    } else {
      setStackQuantityData((prev) => ({ ...prev, newQuantity: quantity }));
    }
  };

  const handleValidation = () => {
    if (!formData.product) {
      alert('품목을 입력해주세요.');
      return false;
    }
    if (!formData.warehouse) {
      alert('창고를 입력해주세요.');
      return false;
    }
    if (!formData.quantity) {
      alert('수량을 입력해주세요.');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const isSuccessValidation = handleValidation();
    if (!isSuccessValidation) return;
    try {
      const { warehouse, product } = formData;
      const { newQuantity } = stackQuantityData;

      await fnInsertInventoryAdjustment({
        nextStackQuantity: newQuantity,
        productId: product,
        warehouseId: warehouse,
        adminId,
        causeOfChange: inventoryCouse.of_change_adjustment,
      });

      // 클라이언트단 반응
      setTableDatas((prev) => {
        const newTableDatas = prev.map((tableData) => {
          const isEqualProductId = tableData.product_id === product;
          if (isEqualProductId) {
            return { ...tableData, [warehouse]: newQuantity };
          } else {
            return tableData;
          }
        });
        return newTableDatas;
      });

      alert('재고를 성공적으로 조정했습니다.');
      setIsOpenAdjustmentModal(false);
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <Modal
      centered
      open
      title="재고조정"
      footer={[
        <Button onClick={() => setIsOpenAdjustmentModal(false)}>취소</Button>,
        <Button type="primary" onClick={handleSubmit}>
          <SaveOutlined /> 저장
        </Button>,
      ]}
      onCancel={() => setIsOpenAdjustmentModal(false)}
    >
      <div style={{ padding: '20px' }}>
        <Container>
          <Title>일자</Title>
          <div
            style={{
              width: '90%',
              borderBottom: '1px solid lightGray',
              padding: '5px 12px',
            }}
          >
            {formData.inventoryChangeDate}
          </div>
        </Container>
        <Container>
          <Title>품목</Title>
          <Select
            options={options.product}
            value={formData.product}
            onSelect={(value) =>
              setFormData((prev) => ({ ...prev, product: value }))
            }
            style={{ width: '90%', borderBottom: '1px solid lightGray' }}
            bordered={false}
            placeholder="품목을 선택해주세요."
          />
        </Container>
        <Container>
          <Title>창고</Title>
          <Select
            options={options.warehouse}
            value={formData.warehouse}
            onSelect={(value) =>
              setFormData((prev) => ({ ...prev, warehouse: value }))
            }
            style={{ width: '90%', borderBottom: '1px solid lightGray' }}
            bordered={false}
            placeholder="창고를 선택해주세요."
          />
        </Container>
        <Container style={{ position: 'relative' }}>
          <Title>수량</Title>
          <Input
            value={formData.quantity}
            onChange={(e) => {
              setFormData((prev) => ({ ...prev, quantity: e.target.value }));
              handleChangeQuantity(e);
            }}
            type="number"
            style={{
              width: '90%',
              borderBottom: '1px solid lightGray',
              borderRadius: '0px',
              textAlign: 'end',
            }}
            bordered={false}
            placeholder={
              !isInputable
                ? '먼저 품목과 창고를 선택해주세요.'
                : '(+, -) 수량를 입력해주세요.'
            }
            disabled={!isInputable}
          />
          <Radio.Group
            onChange={(e) => {
              setQtyType(e.target.value);
              setFormData((prev) => ({ ...prev, quantity: 0 }));
              setStackQuantityData((prev) => ({ ...prev, newQuantity: 0 }));
            }}
            value={qtyType}
            style={{
              width: '40%',
              padding: '3px',
              borderRadius: '20px',
              position: 'absolute',
              left: '30px',
              bottom: '3px',
              textAlign: 'center',
            }}
          >
            <Radio value="variation">증감</Radio>
            <Radio value="absolute">지정</Radio>
          </Radio.Group>
        </Container>
        <Container
          style={{
            backgroundColor: '#efefef',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
          <Card style={{ width: '45%' }}>
            <Statistic
              value={stackQuantityData.originalQuantity}
              title="기존 수량"
            />
          </Card>
          <Card style={{ width: '45%' }}>
            <Statistic
              value={stackQuantityData.newQuantity}
              title="조정 후 수량"
            />
          </Card>
        </Container>
      </div>
    </Modal>
  );
};

export default AdjustmentModal;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;
const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
`;

AdjustmentModal.propTypes = {
  setIsOpenAdjustmentModal: PropTypes.func.isRequired,
  setTableDatas: PropTypes.func.isRequired,
};
