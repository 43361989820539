import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AimOutlined } from '@ant-design/icons';

import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
  darkModeColors,
  lightModeColors,
} from '../../constants';
import useCalcAchievementRate from '../../hook/useCalcAchievementRate';

const SalesSummaryStatistic = (props) => {
  const { salesSummaryData, monthValue, isLargeScreen } = props;

  const { darkMode } = useSelector((state) => state.darkMode);

  const Colors = darkMode ? darkModeColors : lightModeColors;
  const bgColor = darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND;
  const fontColor = darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR;

  const achievementRate = useCalcAchievementRate({
    salesAmount: salesSummaryData,
    salesMonth: monthValue,
  });

  const headerMonthText =
    monthValue?.slice(-2)[0] === '0'
      ? monthValue.slice(-1)
      : monthValue.slice(-2);

  return (
    <div
      style={{
        // border: '1px solid #efefef',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: isLargeScreen ? 'row' : 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        padding: '10px',
        color: 'gray',
        backgroundColor: bgColor,
      }}
    >
      <h4>{headerMonthText}월</h4>
      <div
        style={{
          position: 'absolute',
          top: isLargeScreen ? '5px' : '10px',
          right: '10px',
          color: Colors.armsone_red,
        }}
      >
        <AimOutlined style={{ fontSize: '12px' }} />
        {achievementRate}
        {typeof achievementRate === 'number' && '%'}
      </div>
      <div>{salesSummaryData.toLocaleString()}원</div>
    </div>
  );
};

export default SalesSummaryStatistic;

SalesSummaryStatistic.propTypes = {
  salesSummaryData: PropTypes.number.isRequired,
  monthValue: PropTypes.string.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
};
