import { Table, Tag } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AdminValue from './AdminValue';
import ResponseDateValue from './ResponseDateValue';
import AccountAndCounsellingInfoValue from './AccountAndCounsellingInfoValue';

const CounsellingTableDisplay = (props) => {
  const { counsellingTableDatas, fetchLoading } = props;

  const displayValueOrDash = (value) => {
    return value || '-';
  };

  const tableColumns = useMemo(
    () => [
      {
        title: '번호',
        dataIndex: 'id',
        key: 'id',
        width: '6%',
        align: 'center',
      },
      {
        title: '업체명(연락처)',
        dataIndex: '',
        key: [
          'account_name',
          'route',
          'counselling_type',
          'purchase_intention',
          'reaction_level',
          'callback',
          'is_pursuaded',
        ],
        width: '52%',
        align: 'center',
        render: (data) => {
          return <AccountAndCounsellingInfoValue data={data} />;
        },
      },
      {
        title: '담당자',
        dataIndex: 'admin_id',
        key: 'admin_id',
        width: '7%',
        align: 'center',
        render: (value) => {
          return <AdminValue adminId={value} />;
        },
      },
      {
        title: '상담일',
        dataIndex: '',
        key: 'counselling_date',
        width: '8%',
        align: 'center',
        render: (data) => (
          <DateStyle>{displayValueOrDash(data.counselling_date)}</DateStyle>
        ),
      },
      {
        title: '샘플배송',
        dataIndex: '',
        key: ['delivery_date'],
        width: '8%',
        align: 'center',
        render: (data) => (
          <DateStyle>{displayValueOrDash(data.delivery_date)}</DateStyle>
        ),
      },
      {
        title: '콜백여부',
        dataIndex: '',
        key: 'response_date',
        width: '8%',
        align: 'center',
        render: (data) => {
          return (
            <ResponseDateValue
              displayValueOrDash={displayValueOrDash}
              counsellingId={data.id}
            />
          );
        },
      },
      {
        title: '구매일',
        dataIndex: '',
        key: 'purchase_date',
        width: '8%',
        align: 'center',
        render: (data) => {
          return (
            <DateStyle>{displayValueOrDash(data.purchase_date)}</DateStyle>
          );
        },
      },
    ],
    []
  );

  return (
    <Table
      className="counselling-table"
      columns={tableColumns}
      dataSource={counsellingTableDatas}
      loading={fetchLoading}
    />
  );
};

export default CounsellingTableDisplay;

CounsellingTableDisplay.propTypes = {
  counsellingTableDatas: PropTypes.instanceOf(Array).isRequired,
  fetchLoading: PropTypes.bool.isRequired,
};

const DateStyle = styled.div`
  font-size: 11.5px;
  color: gray;
`;
