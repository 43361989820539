import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Select, Table } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';

const DeniedComponent = (props) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState('');
  const { deniedDatas, setDeniedDatas } = props;

  useEffect(() => {
    const getSelectOptions = async () => {
      const accounts = await dispatch(asyncReadDataFetch({ table: 'account' }))
        .unwrap()
        .then((res) => res);
      const acctOptions = accounts.map((account) => ({
        label: `${account.name} / ${account.region} / ${account.mobile}`,
        value: account.code,
      }));
      setOptions([...acctOptions]);
    };
    getSelectOptions();
  }, [dispatch]);

  const handleAddClick = async () => {
    if (selected.length === 0 || !selected) {
      alert('거래처를 선택한 후 추가해주세요.');
      return;
    }

    try {
      const createDate = await dispatch(
        asyncCreateDataFetch({
          table: 'propose_reject',
          account_code: selected,
        })
      )
        .unwrap()
        .then((res) => res[0].create_date);

      const insertedAcctInfo = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'account',
          eqKey: 'code',
          eqValue: selected,
        })
      )
        .unwrap()
        .then((res) => res[0]);

      const { name, mobile } = insertedAcctInfo;
      const key = uuid();
      const newDeniedAcct = {
        account_name: name,
        account_code: selected,
        account_contact_information1: mobile,
        key,
        create_date: createDate,
      };

      setDeniedDatas((prev) => [...prev, newDeniedAcct]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteClick = async (createDate) => {
    try {
      await dispatch(
        asyncDeleteDataFetch({ table: 'propose_reject', createDate })
      );
      setDeniedDatas((prev) =>
        prev.filter((data) => data.create_date !== createDate)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const column = [
    { title: '거래처', dataIndex: 'account_name', width: '33%' },
    { title: '거래처코드', dataIndex: 'account_code', width: '33%' },
    {
      title: '전화번호',
      dataIndex: 'account_contact_information1',
      width: '33%',
    },
    {
      title: '',
      render: (_, data) => (
        <Popconfirm
          okText="네"
          cancelText="아니요"
          onConfirm={() => handleDeleteClick(data.create_date)}
          title="제안거부업체에서 삭제하시겠습니까?"
          placement="left"
        >
          <Button size="small" type="primary" danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            border: '0.5px solid #efefef',
            padding: '10px',
            fontSize: '15px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#fafafa',
            width: '50px',
            marginRight: '5px',
          }}
        >
          <Button size="small" type="link">
            <SearchOutlined style={{ fontSize: '16px' }} />
          </Button>
        </div>
        <Select
          size="large"
          style={{ width: '100%', marginRight: '5px' }}
          placeholder="제안거부업체로 등록할 거래처를 선택해주세요."
          showSearch
          options={options}
          filterOption={(input, option) => option.label.includes(input)}
          onSelect={(value) => setSelected(value)}
        />
        <Button type="primary" size="large" onClick={handleAddClick}>
          <PlusOutlined />
        </Button>
      </div>
      <Table
        style={{ marginTop: '5px' }}
        dataSource={deniedDatas}
        columns={column}
      />
    </div>
  );
};

export default DeniedComponent;

DeniedComponent.propTypes = {
  deniedDatas: PropTypes.instanceOf(Array).isRequired,
  setDeniedDatas: PropTypes.func.isRequired,
};
