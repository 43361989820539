import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import MonthlyCalendar from './MonthlyCalendar';

import { asyncReadRangeDataFetch } from '../../redux/modules/shared/readRangeData';
import getSalesStatusDatasByCategory from '../../utils/getSalesStatusDatasByCategory';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import ThirtyDaysDailyChart from './ThirtyDaysDailyChart';
import AnalyticsStandard from './AnalyticsStandard';
import ThirtyDaysHourlyChart from './ThirtyDaysHourlyChart';
import SalesLevelChart from './SalesLevelChart';
import SalesSummaryStatistic from './SalesSummaryStatistic';
import SalesAverageStatistic from './SalesAverageStatistic';
import SalesByProductChart from './SalesByProductChart';
import { asyncReadMatchDataFetch } from '../../redux/modules/shared/readMatchData';
import { DARK_MODE_LAYOUT_COLOR } from '../../constants';
import RefreshTimer from './RefreshTimer';
import BotOperationChecker from './BotOperationChecker';
import HundredTwentyDaysChart from './HundredTwentyDaysChart';
import DeliveryStatus from './DeliveryStatus';
import CourierAndWarehouseStatus from './CourierAndWarehouseStatus';
import MajorStatus from './MajorStatus';
import InventoryStatus from './InventoryStatus';
import useGetFirestoreData from '../../hook/useGetFirestoreData';
import useGetDeliveryStatusData from '../../hook/useGetDeliveryStatusData';
import useGetCourierStatusData from '../../hook/useGetCourierStatusData';
import useGetWarhouseStatusData from '../../hook/useGetWarehouseStatusData';
import useGetInventoryForecastData from '../../hook/useGetInventoryForecastData';
import ThreeYearsMonthlyChart from './ThreeYearsMonthlyChart';
import useGetRangeText from '../../hook/useGetRangeText';

const MonthlyData = () => {
  const dispatch = useDispatch();

  const { darkMode } = useSelector((state) => state.darkMode);

  const { getSalesStatusDatasByDaily } = getSalesStatusDatasByCategory();

  const [firestoreData, firestoreId] = useGetFirestoreData();
  const deliveryStatusData = useGetDeliveryStatusData(firestoreData);
  const courierStatusData = useGetCourierStatusData(firestoreData);
  const warehouseStatusData = useGetWarhouseStatusData(firestoreData);
  const rangeText = useGetRangeText(firestoreId);
  const [forecastData, forecastUpdatedAt] = useGetInventoryForecastData();

  const [monthValue, setMonthValue] = useState(dayjs().format('YYYY-MM'));
  const [calendarData, setCalendarData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [calendarRenderVal, setCalendarRenderVal] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [curMonthSalesData, setCurMonthSalesData] = useState([]);
  const [numberOfMonths, setNumberOfMonths] = useState(0);
  const [holidaysData, setHolidaysData] = useState([]);

  const [screenSizeLevel, setScreenSizeLevel] = useState(1);

  // 공휴일 데이터 생성
  const getHolidaysData = async () => {
    // 연
    const year = monthValue.slice(0, 4);
    const month = monthValue.slice(-2);

    // 데이터 페치
    const fetchedHolidaysData = await dispatch(
      asyncReadMatchDataFetch({
        table: 'calendar_holidays',
        match: {
          year,
          month,
        },
        order: 'id',
        limit: 100,
      })
    ).unwrap();

    // 자릿수 포맷팅
    const formattedHolidaysData = fetchedHolidaysData.map((item) => {
      const formattedMonth =
        item.month.toString().length === 2 ? item.month : `0${item.month}`;
      const formattedDay =
        item.day.toString().length === 2 ? item.day : `0${item.day}`;
      const date = `${item.year}-${formattedMonth}-${formattedDay}`;
      return { ...item, date };
    });

    setHolidaysData(formattedHolidaysData);
  };

  // 월단위 데이터 생성
  const getCalendarData = async () => {
    try {
      setDataLoading(true);

      // 해당 월 날짜 데이터
      const startDate = dayjs(monthValue).startOf('month').format('YYYY-MM-DD');
      const endDate = dayjs(monthValue).endOf('month').format('YYYY-MM-DD');

      // 데이터 페치
      const fetchedSalesData = await dispatch(
        asyncReadRangeDataFetch({
          table: 'v_sales_status',
          column: 'delivery_date',
          startPt: startDate,
          endPt: endDate,
        })
      ).unwrap();

      // 해당 월 데이터 (필요한 하위 컴포넌트에 뿌려주는 용도의 state)
      setCurMonthSalesData(fetchedSalesData);

      // 데이터 가공
      const salesData = getSalesStatusDatasByDaily(fetchedSalesData).map(
        (item) => ({ date: item.deliveryDate, sales: item.sumPrice })
      );

      // 전날대비 매출
      for (let i = 1; i < salesData.length; i += 1) {
        const currentSales = salesData[i].sales;
        const prevSales = salesData[i - 1].sales;
        salesData[i].salesChange =
          currentSales > prevSales ? 1 : currentSales < prevSales ? -1 : 0;
      }

      // 판매금액값 5단계로 나누기
      const fetchedSalesLevel = await dispatch(
        asyncReadDataFetch({ table: 'calendar_sales_level' })
      )
        .unwrap()
        .then((res) => res[0]);
      const maxSales = Math.max(...salesData.map((item) => item.sales));
      const {
        level1_min,
        level1_max,
        level2_min,
        level2_max,
        level3_min,
        level3_max,
        level4_min,
        level4_max,
        level5_min,
      } = fetchedSalesLevel;
      const result = salesData.map((item) => {
        const { sales } = item;
        if (maxSales === sales) {
          item.topSales = true;
        }
        if (level1_min <= sales && level1_max >= sales) {
          return { ...item, level: 1 };
        } else if (level2_min <= sales && level2_max >= sales) {
          return { ...item, level: 2 };
        } else if (level3_min <= sales && level3_max >= sales) {
          return { ...item, level: 3 };
        } else if (level4_min <= sales && level4_max >= sales) {
          return { ...item, level: 4 };
        } else if (level5_min <= sales) {
          return { ...item, level: 5 };
        } else {
          return item;
        }
      });

      await setCalendarData([...result]);
      setDataLoading(false);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  useEffect(() => {
    const isCurMonth = monthValue === dayjs().format('YYYY-MM');
    if (isCurMonth) {
      setNumberOfMonths(Number(dayjs().format('DD')));
    } else {
      setNumberOfMonths(Number(dayjs(monthValue).endOf('month').format('DD')));
    }
    getHolidaysData();
    getCalendarData();
  }, [monthValue, dispatch]);

  const handlePanel = (value) => {
    const year = value.$y.toString();
    const month = (value.$M + 1).toString().padStart(2, '0');
    const result = `${year}-${month}`;
    setMonthValue(result);
  };

  const handleSelect = (value) => {
    handlePanel(value);
    setCalendarRenderVal(value);
  };

  const handleArrow = (direction) => {
    const currentDate = calendarRenderVal;
    let newDate = null;

    if (direction === 'left') {
      newDate = currentDate.subtract(1, 'month');
    }
    if (direction === 'right') {
      newDate = currentDate.add(1, 'month');
    }

    handlePanel(newDate);
    setCalendarRenderVal(newDate);
  };

  const handleToday = () => {
    const curMonth = dayjs().format('YYYY-MM');
    const curDate = dayjs().format('YYYY-MM-DD');
    setMonthValue(curMonth);
    setSelectedDate(curDate);
    setCalendarRenderVal(dayjs());
  };

  useEffect(() => {
    const screenHeight = window.screen.height;

    if (screenHeight >= 1500) {
      setScreenSizeLevel(3);
    } else if (screenHeight >= 1200) {
      setScreenSizeLevel(2);
    } else {
      setScreenSizeLevel(1);
    }
  }, []);

  const isTwoOrHigherScreenSizeLevel = useMemo(
    () => screenSizeLevel >= 2,
    [screenSizeLevel]
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        backgroundColor: darkMode && DARK_MODE_LAYOUT_COLOR,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '10px',
          marginBottom: '10px',
        }}
      >
        <div style={{ width: '75%' }}>
          <MonthlyCalendar
            handlePanel={handlePanel}
            handleArrow={handleArrow}
            calendarData={calendarData}
            dataLoading={dataLoading}
            calendarRenderVal={calendarRenderVal}
            handleSelect={handleSelect}
            setSelectedDate={setSelectedDate}
            monthValue={monthValue}
            handleToday={handleToday}
            holidaysData={holidaysData}
            isLargeScreen={isTwoOrHigherScreenSizeLevel}
            screenSizeLevel={screenSizeLevel}
          />
        </div>
        <div style={{ width: '25%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              gap: '10px',
            }}
          >
            <div style={{ height: '17.5%' }}>
              <AnalyticsStandard />
            </div>
            <div
              style={{
                height: isTwoOrHigherScreenSizeLevel ? '20%' : '12.5%',
                flexDirection: isTwoOrHigherScreenSizeLevel ? 'column' : 'row',
                display: 'flex',
                gap: '10px',
              }}
            >
              <SalesSummaryStatistic
                salesSummaryData={calendarData.reduce(
                  (acc, cur) => acc + cur.sales,
                  0
                )}
                monthValue={monthValue}
                isLargeScreen={isTwoOrHigherScreenSizeLevel}
              />
              <SalesAverageStatistic
                salesAverageData={
                  calendarData.reduce((acc, cur) => acc + cur.sales, 0) /
                    numberOfMonths || 0
                }
                isLargeScreen={isTwoOrHigherScreenSizeLevel}
              />
            </div>
            <div
              style={{
                height: isTwoOrHigherScreenSizeLevel ? '50%' : '55%',
                display: 'flex',
                gap: '10px',
              }}
            >
              <SalesLevelChart
                salesLevelData={calendarData.map((item) => item.level)}
                monthValue={monthValue}
              />
              <SalesByProductChart
                curMonthSalesData={curMonthSalesData}
                monthValue={monthValue}
              />
            </div>
            <div
              style={{
                height: isTwoOrHigherScreenSizeLevel ? '12.5%' : '15%',
                display: 'flex',
                gap: '10px',
              }}
            >
              <div style={{ width: '50%' }}>
                <RefreshTimer isLargeScreen={isTwoOrHigherScreenSizeLevel} />
              </div>
              <div style={{ width: '50%' }}>
                <BotOperationChecker
                  isLargeScreen={isTwoOrHigherScreenSizeLevel}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          marginBottom: '10px',
        }}
      >
        <div style={{ width: '24.8%' }}>
          <ThirtyDaysHourlyChart selectedDate={selectedDate} />
        </div>
        <div style={{ width: '24.8%' }}>
          <ThirtyDaysDailyChart />
        </div>
        <div style={{ width: '50.4%' }}>
          <HundredTwentyDaysChart />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flex: '1',
          marginBottom: '10px',
        }}
      >
        <div style={{ flex: '1' }}>
          <DeliveryStatus
            deliveryStatusData={deliveryStatusData}
            rangeText={rangeText}
          />
        </div>
        <div style={{ flex: '1' }}>
          <CourierAndWarehouseStatus
            courierStatusData={courierStatusData}
            warehouseStatusData={warehouseStatusData}
            rangeText={rangeText}
          />
        </div>
        <div style={{ flex: '1' }}>
          <MajorStatus
            forecastData={forecastData}
            forecastUpdatedAt={forecastUpdatedAt}
          />
        </div>
        <div style={{ flex: '1' }}>
          <InventoryStatus
            forecastData={forecastData}
            forecastUpdatedAt={forecastUpdatedAt}
          />
        </div>
      </div>
      <div style={{ flex: '1' }}>
        <ThreeYearsMonthlyChart />
      </div>
    </div>
  );
};

export default MonthlyData;
