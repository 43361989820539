import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import dayjs from 'dayjs';

import TableFilterHandler from './TableFilterHandler';
import TotalDataViewer from './TotalDataViewer';
import TableSortHandler from './TableSortHandler';
import WordShortHandler from './WordShortHandler';

const baseColumn = [
  {
    title: '할인수량',
    dataIndex: 'dcQuantity',
    key: 'dcQuantity',
    align: 'center',
    render: (value) => <div>{value?.toLocaleString()}</div>,
  },
  {
    title: '수량',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
    render: (value) => <div>{value?.toLocaleString()}</div>,
  },
  {
    title: '공급가액',
    dataIndex: 'supplyPrice',
    key: 'supplyPrice',
    align: 'center',
    render: (value) => <div>{value?.toLocaleString()}</div>,
  },
  {
    title: '부가세',
    dataIndex: 'vatPrice',
    key: 'vatPrice',
    align: 'center',
    render: (value) => <div>{value?.toLocaleString()}</div>,
  },
  {
    title: '합계',
    dataIndex: 'sumPrice',
    key: 'sumPrice',
    align: 'center',
    render: (value) => <div>{value?.toLocaleString()}</div>,
  },
];
const lineColumn = [
  {
    title: '판입시점',
    dataIndex: 'salesDate',
    key: 'salesDate',
    align: 'center',
    render: (value) => dayjs(value)?.format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '판매일',
    dataIndex: 'deliveryDate',
    key: 'deliveryDate',
    align: 'center',
  },
  {
    title: '첫 판매',
    dataIndex: 'firstSale',
    key: 'firstSale',
    align: 'center',
  },
  {
    title: '거래처코드',
    dataIndex: 'accountCode',
    key: 'accountCode',
    align: 'center',
  },
  {
    title: '사업자번호',
    dataIndex: 'businessNumber',
    key: 'businessNumber',
    align: 'center',
  },
  {
    title: '판매처명',
    dataIndex: 'accountName',
    key: 'accountName',
    align: 'center',
  },
  {
    title: '지역',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
  },
  {
    title: '출하창고',
    dataIndex: 'warehouseName',
    key: 'warehouseName',
    align: 'center',
  },
  {
    title: '적요',
    dataIndex: 'briefs',
    key: 'briefs',
    align: 'center',
  },
  {
    title: '메모',
    dataIndex: 'memo',
    key: 'memo',
    align: 'center',
  },
  {
    title: '모바일',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'center',
  },
  {
    title: '품목명',
    dataIndex: 'productName',
    key: 'productName',
    align: 'center',
  },
  {
    title: '검색창내용',
    dataIndex: 'search',
    key: 'search',
    align: 'center',
  },
  {
    title: '기본결제유형',
    dataIndex: 'dealType',
    key: 'dealType',
    align: 'center',
    render: (value) => (
      <div>{value === '11' ? '부가세율 적용' : '부가세율 미적용'}</div>
    ),
  },
  {
    title: '결제조건',
    dataIndex: 'paymentTerms',
    key: 'paymentTerms',
    align: 'center',
  },
  ...baseColumn,
];
const dailyColumn = [
  {
    title: '판매일',
    dataIndex: 'deliveryDate',
    key: 'deliveryDate',
    align: 'center',
  },
  ...baseColumn,
];
const monthlyColumn = [
  {
    title: '판매월',
    dataIndex: 'yearMonthDate',
    key: 'yearMonthDate',
    align: 'center',
  },
  ...baseColumn,
];
const orderColumn = [
  {
    title: '주문일',
    dataIndex: 'regDate',
    key: 'regDate',
    align: 'center',
    render: (value) => <div>{value && value.slice(0, 10)}</div>,
  },
  {
    title: '판매일',
    dataIndex: 'deliveryDate',
    key: 'deliveryDate',
    align: 'center',
  },
  {
    title: '첫 판매',
    dataIndex: 'firstSale',
    key: 'firstSale',
    align: 'center',
  },
  {
    title: '거래처코드',
    dataIndex: 'accountCode',
    key: 'accountCode',
    align: 'center',
  },
  {
    title: '사업자번호',
    dataIndex: 'businessNumber',
    key: 'businessNumber',
    align: 'center',
  },
  {
    title: '판매처명',
    dataIndex: 'accountName',
    key: 'accountName',
    align: 'center',
  },
  {
    title: '지역',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
  },
  {
    title: '적요',
    dataIndex: 'briefs',
    key: 'briefs',
    align: 'center',
  },
  {
    title: '메모',
    dataIndex: 'memo',
    key: 'memo',
    align: 'center',
  },
  {
    title: '모바일',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'center',
  },
  {
    title: '검색창내용',
    dataIndex: 'search',
    key: 'search',
    align: 'center',
  },
  {
    title: '기본결제유형',
    dataIndex: 'dealType',
    key: 'dealType',
    align: 'center',
    render: (value) => (
      <div>{value === '11' ? '부가세율 적용' : '부가세율 미적용'}</div>
    ),
  },
  {
    title: '결제조건',
    dataIndex: 'paymentTerms',
    key: 'paymentTerms',
    align: 'center',
  },
  ...baseColumn,
];
const productColumn = [
  {
    title: '품목코드',
    dataIndex: 'productCode',
    key: 'productCode',
    align: 'center',
  },
  {
    title: '품목명',
    dataIndex: 'productName',
    key: 'productName',
    align: 'center',
  },
  ...baseColumn,
];
const accountColumn = [
  {
    title: '거래처코드',
    dataIndex: 'accountCode',
    key: 'accountCode',
    align: 'center',
  },
  {
    title: '거래처명',
    dataIndex: 'accountName',
    key: 'accountName',
    align: 'center',
  },
  {
    title: '첫 판매',
    dataIndex: 'firstSale',
    key: 'firstSale',
    align: 'center',
  },
  {
    title: '사업자번호',
    dataIndex: 'businessNumber',
    key: 'businessNumber',
    align: 'center',
  },
  {
    title: '지역',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
  },
  {
    title: '적요',
    dataIndex: 'briefs',
    key: 'briefs',
    align: 'center',
  },
  {
    title: '모바일',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'center',
  },
  {
    title: '품목명',
    dataIndex: 'productName',
    key: 'productName',
    align: 'center',
  },
  {
    title: '검색창내용',
    dataIndex: 'search',
    key: 'search',
    align: 'center',
  },
  ...baseColumn,
];
const adminColumn = [
  {
    title: '담당자',
    dataIndex: 'adminCode',
    key: 'adminCode',
    align: 'center',
  },
  ...baseColumn,
];
const immutableColumns = {
  lineColumn,
  dailyColumn,
  monthlyColumn,
  orderColumn,
  productColumn,
  accountColumn,
  adminColumn,
};

const SalesStatusTable = (props) => {
  const {
    loading,
    salesStatusDatas,
    totalSalesStatusData,
    category,
    setSalesStatusDatas,
  } = props;

  const [columns, setColumns] = useState({
    lineColumn,
    dailyColumn,
    monthlyColumn,
    orderColumn,
    productColumn,
    accountColumn,
    adminColumn,
  });

  const getVisibleColumn = () => {
    if (category === 'line') {
      return columns.lineColumn;
    } else if (category === 'daily') {
      return columns.dailyColumn;
    } else if (category === 'monthly') {
      return columns.monthlyColumn;
    } else if (category === 'order') {
      return columns.orderColumn;
    } else if (category === 'product') {
      return columns.productColumn;
    } else if (category === 'account') {
      return columns.accountColumn;
    } else if (category === 'admin') {
      return columns.adminColumn;
    } else {
      return;
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          backgroundColor: '#f9f9f9',
          padding: '10px',
          width: 'content-fit',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <TableFilterHandler
            setColumns={setColumns}
            category={category}
            getVisibleColumn={getVisibleColumn}
            immutableColumns={immutableColumns}
          />
          <TableSortHandler
            setSalesStatusDatas={setSalesStatusDatas}
            getVisibleColumn={getVisibleColumn}
          />
          <WordShortHandler
            salesStatusDatas={salesStatusDatas}
            setSalesStatusDatas={setSalesStatusDatas}
            getVisibleColumn={getVisibleColumn}
          />
        </div>
        <TotalDataViewer
          totalSalesStatusData={totalSalesStatusData}
          category={category}
        />
      </div>
      <div
        className="border-table sales-status"
        style={{ width: '100%', overflow: 'auto', maxHeight: '80vh' }}
      >
        <Table
          dataSource={salesStatusDatas}
          columns={getVisibleColumn()}
          className="sales-table"
          pagination={false}
          loading={loading.tableDatas}
          bordered
          summary={() => {
            if (salesStatusDatas.length === 0) {
              return;
            }
            let emptyCellLength = getVisibleColumn().length - 5;
            const emptyCell = Array.from(
              { length: emptyCellLength },
              (_, index) => index
            );
            return (
              <Table.Summary.Row
                style={{ backgroundColor: '#f7f7f7', textAlign: 'center' }}
              >
                {emptyCell.map((_) => (
                  <Table.Summary.Cell />
                ))}
                <Table.Summary.Cell>
                  {totalSalesStatusData.totalDcQuantity.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {totalSalesStatusData.totalQuantity.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {totalSalesStatusData.totalSupply.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {totalSalesStatusData.totalVat.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {totalSalesStatusData.totalSum.toLocaleString()}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>
    </div>
  );
};

export default SalesStatusTable;

SalesStatusTable.propTypes = {
  loading: PropTypes.instanceOf(Object).isRequired,
  salesStatusDatas: PropTypes.instanceOf(Array).isRequired,
  setSalesStatusDatas: PropTypes.func.isRequired,
  totalSalesStatusData: PropTypes.instanceOf(Object).isRequired,
  category: PropTypes.string.isRequired,
};
