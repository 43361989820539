import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';
import { DownOutlined, FileExcelOutlined } from '@ant-design/icons';

import downloadExcelFile from '../../utils/downloadExcelFile';

const TaxInvoiceExcelHandler = (props) => {
  const { salesStatusDatas, date, category, isExistTaxInvoiceData } = props;

  const { handleTaxInvoiceTable } = downloadExcelFile();

  const getTaxInvoiceExcelDatasForGyeongrinara = () => {
    const excelColumns = [
      '거래처상호',
      '사업자(주민)번호',
      '공급가액',
      '부가세액',
      '합계금액(C+D)',
      '세액종',
      '책번호(권)',
      '책번호(호)',
      '일련번호',
      '거래처대표자명',
      '거래처주소',
      '거래처 종사업장번호',
      '거래처업태',
      '거래처업종',
      '작성일자',
      '비고',
      '현금',
      '수표',
      '어음',
      '외상미수금',
      '영수/청구',
      '년월일',
      '품목',
      '규격',
      '수량',
      '단가',
      '공급가',
      '세액',
      '비고',
      '거래처담당자명1',
      '거래처담당부서명',
      '거래처담당자이메일1',
      'e-mail전송',
      '거래처담당자휴대폰1',
      '발행메세지',
      'lms전송',
      '거래처담당자명2',
      '거래처담당부서명2',
      '거래처담당자이메일2',
      'e-mail전송',
      '거래처담당자휴대폰2',
      '발행메세지',
      'lms전송',
    ];
    const excelRows = [
      ...salesStatusDatas.map((item) => ({
        accountName: item.isDuplicatedAcct ? null : item.accountName,
        businessNumber: item.isDuplicatedAcct ? null : item.businessNumber,
        totalSupplyPrice: item.isDuplicatedAcct ? null : item.totalSupplyPrice,
        totalVatPrice: item.isDuplicatedAcct ? null : item.totalVatPrice,
        totalSumPrice: item.isDuplicatedAcct ? null : item.totalSumPrice,
        taxType: item.isDuplicatedAcct ? null : '과세',
        bookNumberVolumn: null,
        bookNumberNo: null,
        serialNumber: null,
        representative: item.isDuplicatedAcct ? null : item.representative,
        address: item.isDuplicatedAcct ? null : item.address,
        businessCode: null,
        businessType: item.isDuplicatedAcct ? null : item.conditions,
        businessCategory: item.isDuplicatedAcct ? null : item.category,
        currentDate: item.isDuplicatedAcct ? null : item.currentDate,
        bigo: null,
        cash: null,
        check: null,
        note: null,
        receivable: null,
        receipt: item.isDuplicatedAcct ? null : '청구',
        salesDate: item.salesDate,
        product: item.productName,
        size: null,
        quantity: null,
        price: null,
        supplyPrice1: item.supplyPrice,
        vatPrice1: item.vatPrice,
        bigo1: null,
        chargePerson: null,
        chargeDepartment: null,
        chargeEmail: item.isDuplicatedAcct ? null : item.email,
        emailSend: null,
        chargeMobile: null,
        message: null,
        lms: null,
        chargePerson1: null,
        chargeDepartment1: null,
        chargeEmail1: null,
        emailSend1: null,
        chargeMobile1: null,
        message1: null,
        lms1: null,
      })),
    ];
    return { excelColumns, excelRows };
  };

  const getTaxInvoiceExcelDatasForHometax = () => {
    const getExcelColumns = () => {
      const excelColumnsByProduct = [];
      for (let i = 1; i <= 4; i += 1) {
        const columns = [
          `일자${i}`,
          `품목${i}`,
          `규격${i}`,
          `수량${i}`,
          `단가${i}`,
          `공급가액${i}`,
          `세액${i}`,
          `품목비고${i}`,
        ];
        excelColumnsByProduct.push(...columns);
      }
      const excelColumns = [
        '전자(세금)계산서 종류',
        '작성일자',
        '공급자 등록번호',
        '공급자 종사업장번호',
        '공급자 상호',
        '공급자 성명',
        '공급자 사업장주소',
        '공급자 업태',
        '공급자 종목',
        '공급자 이메일',
        '공급받는자 등록번호',
        '공급받는자 종사업장번호',
        '공급받는자 상호',
        '공급받는자 성명',
        '공급받는자 사업장주소',
        '공급받는자 업태',
        '공급받는자 종목',
        '공급받는자 이메일1',
        '공급받는자 이메일2',
        '공급가액 합계',
        '세액 합계',
        '비고',
        ...excelColumnsByProduct,
        '현금',
        '수표',
        '어음',
        '외상미수금',
        '영수(01),청구(02)',
      ];
      return excelColumns;
    };
    const getExcelRows = () => {
      const excelRows = [];
      const arrayForCount = [];
      const countSameAccount = (accountNameArray, targetAccountName) => {
        let countOfSameAccountName = 0;

        for (let i = accountNameArray.length - 1; i >= 0; i -= 1) {
          if (accountNameArray[i] === targetAccountName) {
            countOfSameAccountName += 1;
          } else {
            break;
          }
        }

        return countOfSameAccountName;
      };

      for (let i = 0; i < salesStatusDatas.length; i += 1) {
        const currentData = salesStatusDatas[i];

        const accountCount = countSameAccount(
          arrayForCount,
          currentData.accountName
        );

        const currentProductNo = accountCount + 1;

        if (accountCount < 1 || accountCount > 3) {
          const excelRow = {
            taxInvoiceType: '01',
            currentDate: currentData.currentDate,
            supplierRegNum: '2408801226',
            supplierBusinessNum: '',
            supplierBrandName: '주식회사 한통',
            supplierName: '한병기',
            supplierAddress: '경기도 고양시 일산동구 무궁화로 43-33 7층',
            supplierBusinessType: '도소매업',
            supplierBusinessCategory: '도시락용기',
            supplierEmail: 'hantongbox@naver.com',
            consumerRegNum: currentData.businessNumber,
            consumerBusinessNum: null,
            consumerBrandName: currentData.accountName,
            consumerName: currentData.representative,
            consumerAddress: currentData.address,
            consumerBusinessType: currentData.conditions,
            consumerBusinessCategory: currentData.category,
            consumerEmail1: currentData.email,
            consumerEmail2: null,
            totalSupplyPrice: currentData.supplyPrice,
            totalVatPrice: currentData.vatPrice,
            bigo: null,
            // salesDate1: currentData.salesDate.slice(-2),
            salesDate1: currentData.currentDate.slice(-2),
            productName1: currentData.productName,
            size1: null,
            quantity1: currentData.quantity,
            price1: currentData.price,
            supplyPrice1: currentData.supplyPrice,
            vatPrice1: currentData.vatPrice,
            productBigo1: null,
            numOfProduct: 1,
          };

          excelRows.push(excelRow);
          arrayForCount.push(currentData.accountName);
        } else {
          const excelRow = {};
          const salesDateKey = `salesDate${currentProductNo}`;
          const productNameKey = `productName${currentProductNo}`;
          const sizeKey = `size${currentProductNo}`;
          const quantityKey = `quantity${currentProductNo}`;
          const priceKey = `price${currentProductNo}`;
          const supplyPriceKey = `supplyPrice${currentProductNo}`;
          const vatPriceKey = `vatPrice${currentProductNo}`;
          const productBigoKey = `productBigo${currentProductNo}`;
          // excelRow[salesDateKey] = currentData.salesDate.slice(-2);
          excelRow[salesDateKey] = currentData.currentDate.slice(-2);
          excelRow[productNameKey] = currentData.productName;
          excelRow[sizeKey] = null;
          excelRow[quantityKey] = currentData.quantity;
          excelRow[priceKey] = currentData.price;
          excelRow[supplyPriceKey] = currentData.supplyPrice;
          excelRow[vatPriceKey] = currentData.vatPrice;
          excelRow[productBigoKey] = null;
          const totalSupplyPrice =
            excelRows[excelRows.length - 1].totalSupplyPrice +
            currentData.supplyPrice;
          const totalVatPrice =
            excelRows[excelRows.length - 1].totalVatPrice +
            currentData.vatPrice;
          excelRows[excelRows.length - 1] = {
            ...excelRows[excelRows.length - 1],
            ...excelRow,
            totalSupplyPrice,
            totalVatPrice,
            numOfProduct: currentProductNo,
          };

          arrayForCount.push(currentData.accountName);
        }
      }

      const result = excelRows.map((excelRow) => {
        const { numOfProduct } = excelRow;
        for (let i = 1; i <= 4; i += 1) {
          const notHasProductNo = i;
          if (notHasProductNo > numOfProduct) {
            const excelRowToAdd = {};
            const salesDateKey = `salesDate${notHasProductNo}`;
            const productNameKey = `productName${notHasProductNo}`;
            const sizeKey = `size${notHasProductNo}`;
            const quantityKey = `quantity${notHasProductNo}`;
            const priceKey = `price${notHasProductNo}`;
            const supplyPriceKey = `supplyPrice${notHasProductNo}`;
            const vatPriceKey = `vatPrice${notHasProductNo}`;
            const productBigoKey = `productBigo${notHasProductNo}`;
            excelRowToAdd[salesDateKey] = null;
            excelRowToAdd[productNameKey] = null;
            excelRowToAdd[sizeKey] = null;
            excelRowToAdd[quantityKey] = null;
            excelRowToAdd[priceKey] = null;
            excelRowToAdd[supplyPriceKey] = null;
            excelRowToAdd[vatPriceKey] = null;
            excelRowToAdd[productBigoKey] = null;
            excelRow = { ...excelRow, ...excelRowToAdd };
          }
        }
        const receiptObj = {
          cash: null,
          check: null,
          note: null,
          receivable: null,
          receipt: '01',
        };
        delete excelRow.numOfProduct;
        return { ...excelRow, ...receiptObj };
      });
      return result;
    };

    const excelRows = getExcelRows();
    const excelColumns = getExcelColumns();

    return { excelColumns, excelRows };
  };

  const handleClickExcelButton = async (type) => {
    if (!isExistTaxInvoiceData) {
      alert('데이터가 없으므로 파일을 다운로드할 수 없습니다.');
      return;
    }
    try {
      // 엑셀 타이틀(제목)
      const getExcelTitle = (keyword) => {
        return `${date && date.begin}~${
          date && date.end
        }_세금계산서등록양식(${keyword})_(주)한통.xlsx`;
      };
      // 엑셀 컬럼값, 로우값
      if (type === 'gyeongrinara') {
        const excelTitle = getExcelTitle('경리나라');
        const { excelColumns, excelRows } =
          getTaxInvoiceExcelDatasForGyeongrinara();
        await handleTaxInvoiceTable(
          excelTitle,
          excelColumns,
          excelRows,
          'gyeongrinara'
        );
      } else if (type === 'hometax') {
        const excelTitle = getExcelTitle('홈택스');
        const { excelColumns, excelRows } = getTaxInvoiceExcelDatasForHometax();
        await handleTaxInvoiceTable(
          excelTitle,
          excelColumns,
          excelRows,
          'hometax'
        );
      } else if (type === 'all') {
        const excelTitleForGyeongrinara = getExcelTitle('경리나라');
        const excelTitleForHometax = getExcelTitle('홈택스');
        const excelColumnsForGyeongrinara =
          getTaxInvoiceExcelDatasForGyeongrinara().excelColumns;
        const excelRowsForGyeongrinara =
          getTaxInvoiceExcelDatasForGyeongrinara().excelRows;
        const excelColumnsForHometax =
          getTaxInvoiceExcelDatasForHometax().excelColumns;
        const excelRowsForHometax =
          getTaxInvoiceExcelDatasForHometax().excelRows;
        await handleTaxInvoiceTable(
          excelTitleForGyeongrinara,
          excelColumnsForGyeongrinara,
          excelRowsForGyeongrinara,
          'gyeongrinara'
        );
        await handleTaxInvoiceTable(
          excelTitleForHometax,
          excelColumnsForHometax,
          excelRowsForHometax,
          'hometax'
        );
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            style={{ backgroundColor: '#1d6f42', color: 'white' }}
            onClick={() => handleClickExcelButton('all')}
          >
            <FileExcelOutlined />
            전부/ALL
          </Button>
          <Button
            style={{ backgroundColor: '#1d6f42', color: 'white' }}
            onClick={() => handleClickExcelButton('gyeongrinara')}
          >
            <FileExcelOutlined />
            경리나라
          </Button>
          <Button
            style={{
              backgroundColor: '#1d6f42',
              color: 'white',
            }}
            onClick={() => handleClickExcelButton('hometax')}
          >
            <FileExcelOutlined />
            홈택스
          </Button>
        </div>
      }
    >
      <Button
        style={{
          borderRadius: '20px',
        }}
      >
        양식선택
        <DownOutlined />
      </Button>
    </Popover>
  );
};

export default TaxInvoiceExcelHandler;

TaxInvoiceExcelHandler.propTypes = {
  salesStatusDatas: PropTypes.instanceOf(Array).isRequired,
  date: PropTypes.instanceOf(Object),
  category: PropTypes.string.isRequired,
  isExistTaxInvoiceData: PropTypes.bool.isRequired,
};
